console.log('Vite ⚡️ Rails')

// Import Rails libraries
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
Rails.start()
Turbolinks.start()

// Import Alpine
import 'alpinejs'

import 'chartkick/chart.js'
import 'cocoon-js'

// Import Stimulus controllers
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()
const controllers = import.meta.globEager('../controllers/**/*_controller.js')
registerControllers(application, controllers)

import '../addons/photoswipe'
import '../addons/tom_select'
import '../addons/home/slider'
import '../addons/hobbies/tournament_bracket'

// Import stylesheets
import '../stylesheets/application.css'
